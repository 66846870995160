body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: white !important;
  text-decoration: none !important;
}
a:hover{
  opacity: 0.7;
}
a.current{
  color: #55c521 !important;
  width: 100%;
}
a.current:hover{
  color: white !important;
  width: 100%;
}
h4{
  color:white !important;
  margin-top:-2% !important;
  padding-bottom: 2%;
  text-align: center;
}
.centerInput{
  text-align: center !important;
}
select{
  padding: 3px 5px;
}
#root{
  background-color: #3E444A !important;
}
.button {
  display: inline-block;
}
.fullPageDiv{
  width: 90%;
  margin-bottom:25px;
}
.authWindow{
  background-color: #3E444A;
  font-size: calc(10px + 1vmin);
  color: white;
  height: 100vh;
  /*margin: 10px;*/
}
.loginbox {
  background-color: #33383E;
  max-width: 360px;
  margin: 0 auto;
  border: 1px solid #2D3237;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -180px;
  -webkit-transform: translate(0px, -50%);
  -moz-transform: translate(0px, -50%);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.loginbox .head {
  padding: 40px 35px;
}
.loginbox .body {
  padding: 40px 35px
}
.loginbox .body p {
  font-size: 14px;
  line-height: 1.2em;
  margin-bottom: 10px;
  color: #8D969E;
}
.btn-primary {
  background-color: #4A8B2C;
  color: #25282C;
  line-height: 1;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #5AA835;
  color: #25282C;
}
/* Form styling */
div.form {
  margin-left: 25px;
  margin-bottom: 15px;
}
.form-control {
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
}
.form-group.tight {
  margin-bottom: 2px;
}
.form-login .form-control {
  background-color: #2D3237;
  border: 1px solid #212427;
  height: 54px;
  box-shadow: none;
}
.form-login .form-control:focus {
  background-color: #fff;
  color: #444;
}
.form-supergroup .form-group .form-control {
  margin-top: -1px;
}
.form-supergroup .form-group:first-child .form-control {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-supergroup .form-group:last-child .form-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-login .form-group {
  margin: 0;
}
.form-login .btn-primary {
  margin-top: 15px;
  text-align: left;
  padding: 24px 20px;
  background-color: #55c521;
  color: white;
  opacity: 0.9;
  line-height: 1;
}
.form-login .btn-primary:hover, .btn-primary:focus {
  background-color: #55c521;
  color: white;
  opacity: 1.0;
}
.mainWindow{
  display : flex;
  flex-direction: column;
  align-items:center;
  background-color: #3E444A;
  font-size: calc(10px + 1vmin);
  color: white;
  width: 83%;
  float : right;
  overflow-y: scroll;
  height: 100vh;
}
.App-title{
  margin-top: 7.5%;
}
.sidebar{
  background-color: #33383E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 1vmin);
  float : left;
  color: white;
  width: 17%;
  height: 100vh;
  overflow: hidden;
}
.logo-img{
  position: fixed;
  top: 1% !important;
  margin-left: -1%;
}
.individualContainer{
  background-color: #33383E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: white;
  overflow: hidden;
  padding: 40px 0px

}
.startCreateButton{
  display: inline-block;
  background-color: #4a8b2c;
  color: white;
  float : left;
  width: webkit-fill-available;
  margin: 10px;
}
.startCreateButton:hover {
    opacity: 0.8;
}
.endDeleteButton{
  display: inline-block;
  background-color: #636c76;
  color: white;
  float : right;
  width: webkit-fill-available;
  margin: 10px;
}
.endDeleteButton:hover {
    opacity: 0.8;
}
.consoleButton {
  display: inline-block;
  background-color: #55c521;
  color: white;
  width: webkit-fill-available;
  margin: 10px;
  opacity: 0.9;
}
.consoleButton:hover:enabled {
  opacity: 1.0;
}
.consoleButton:disabled {
  background-color: #636c76;
}
.informationalLabel{
  top : 0;
}

th, td {
  padding: 10px 20px;
}
.Section__container___3YYTG{
  position: fixed;
  top:0;
  width: 100%;
}
.Nav__navBar___xtCFA{
  border: none !important;
  background-color: #33383E !important;
  color:white;
}

.saveButton{
  display: block;
  margin: 2% auto 0 auto;
  text-align: center;
  
}

.individualContainerTable tr{
  text-align: center;
}

.individualContainerTable td input{
  width: 70px;
}

.list-status {
  list-style: none;
}
.status-label {
  color: #8C969E;
}

tr.spaceOver td {
    padding-top: 30px;
}
.loactionBehaviorTable{
  padding: 0px 10px;
}
.loactionBehaviorTable td{
 padding: 0px 20px
}
.loactionBehaviorTable tr{
  text-align: center;
}

.loactionBehaviorTable input{
  width: 70px;
}
.dateTable {
    width: auto; /* Adjust table width as needed */
    border-collapse: collapse; /* Remove spacing between cells */
}

.dateTable th, .dateTable td {
    padding: 8px; /* Add padding for better spacing */
    text-align: center; /* Center-align text */
}

.dateInput {
    /* margin-right: 20px; */
    width: 150px; /*Reduce the width of the input fields */
    padding: 5px 0px; /* Add padding for better appearance */
    text-align: center; /* Center-align the placeholder and text */
    /* border: 1px solid #ccc; Add a border for better visibility */
    border-radius: 4px; /* Add rounded corners */
}